import CONFIG from '../CONFIG.json';
import { buildBody, isEmpty } from './funciones';

// ----------------------------------------------------------------------

export const getProvinces = async(token) => {
    const bodyJson = JSON.stringify(buildBody(token,{}))
    const res = await fetch(`${CONFIG.api}province/list`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getCountries = async(token) => {
    const bodyJson = JSON.stringify(buildBody(token,{}))
    const res = await fetch(`${CONFIG.api}country/list`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}

export const getOrigins = async(token) => {
    const bodyJson = JSON.stringify(buildBody(token,{}))
    const res = await fetch(`${CONFIG.api}origins/list`,{
        method: 'POST',
        body: bodyJson,
        headers: {'Content-Type': 'application/json'}
    });
    const result = await res.json()
    if (result.error === 0 && !isEmpty(result.data)) {
        return result.data;
    }
    return [];
}