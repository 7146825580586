import React from 'react';
// @mui
import { Grid } from '@mui/material';
// components
import RTextField from '../../componentes/forms/inputs/RTextField';
import RSelect from '../../componentes/forms/inputs/RSelect';

import { isEmpty } from '../../auxiliares/funciones';

// ----------------------------------------------------------------------

export default function BusqForm({ showLoader, actualizaCampo, busqueda, provincias, paises }) {
  React.useEffect(() => {
    showLoader(false);
    // eslint-disable-next-line
  },[]);

  const [key, setKey] = React.useState(Math.random());
  const [provs, setProvs] = React.useState([]);

  const compruebaCambios = (e) =>{
      if(e.target.name === 'pais'){
          const listado = provincias.filter(a => a.pais === e.target.value);
          setProvs(isEmpty(listado) ? [{id: "", nombre: '---'}] : listado);
      }
      actualizaCampo(e)
      setKey(Math.random())
  }

  return (
    <form style={{ width: '100%'}} key={key}>
      <Grid container spacing={1}>
        <RTextField type="text" name="texto" label="Texto*" actualizaCampo={actualizaCampo} value={busqueda.texto} md={4} />
        <RSelect name="pais" label="Paises*" actualizaCampo={compruebaCambios} value={busqueda.pais} md={4}
          values={paises}
        />
        <RSelect name="provincia" label="Provincia*" actualizaCampo={actualizaCampo} value={busqueda.provincia} md={4}
          values={provs}
        />
      </Grid>
    </form>
  );
}
